import { defineStore } from 'pinia'
import type { RepaymentLoan } from '@/shared/interfaces'

type CreditStatus = {
  id: string
  href: string
  status: string
}

type Credit = {
  currency: string
  approved_amount: number
  available_amount: number
  principal_used_amount: number
  principal_outstanding_amount: number
  net_outstanding: number
  used_amount: number
  net_used_amount: number
  principal_repaid: number
  net_repaid: number
  amount_repayable: number
  maximum_amount: number
  scheduled_amount: number
  fee_markup: number
  credit_facility_max_tenor: null | any
  available_from: string
  available_until: string
  status: CreditStatus
}

type OrganisationCredit = {
  organisation_id: string
  credit: Credit
}

interface DefaultStateType {
  upcomingRepayments: RepaymentLoan[]
  historicRepayments: RepaymentLoan[]
  fetched: boolean
  fetchedUpcoming: boolean
  loading: boolean
  error: string | null
  errorRepayment: string | null
  repaymentDataSummary: {
    currency: {
      id: string
      code: string
      name: string
    }
    total_oustanding_amount: number
    total_amount_repaid: number
    next_repayment_date: string
    last_repayment_date: string
    next_repayment_amount: number
  }
  balances: OrganisationCredit | null
}

const defaultState: () => DefaultStateType = () => ({
  fetched: false,
  fetchedUpcoming: false,
  loading: false,
  error: null,
  upcomingRepayments: [],
  historicRepayments: [],
  errorRepayment: null,
  repaymentDataSummary: {
    currency: {
      id: '',
      code: '',
      name: '',
    },
    total_oustanding_amount: 0,
    total_amount_repaid: 0,
    next_repayment_date: '',
    last_repayment_date: '',
    next_repayment_amount: 0,
  },
  balances: null,
})

export const useRepayments = defineStore('repayments', {
  state: defaultState,
  actions: {
    async upcomingRepayment(sellerId: string) {
      try {
        this.loading = true
        const { $lenkieBankingApi } = useNuxtApp()

        if (!sellerId) return

        const { data = [] } = await $lenkieBankingApi.get(
          `/repayments/upcoming/${sellerId}`,
        )

        this.upcomingRepayments = data
        this.fetchedUpcoming = true
      } catch (error) {
        this.errorRepayment = 'Failed to fetch repayments'
      }
    },
    async historicalRepayment(sellerId: string) {
      try {
        this.loading = true
        const { $lenkieBankingApi } = useNuxtApp()

        if (!sellerId) return

        const { data = [] } = await $lenkieBankingApi.get(
          `/repayments/historic/${sellerId}`,
        )
        this.fetched = true
        this.historicRepayments = data
      } catch (error) {
        this.errorRepayment = 'Failed to fetch repayments'
      } finally {
        this.loading = false
      }
    },
    async repaymentSummary(sellerId: string) {
      try {
        this.loading = true
        const { $lenkieBankingApi } = useNuxtApp()

        if (!sellerId) return

        const { data = [] } = await $lenkieBankingApi.get(
          `/repayments/summary/${sellerId}`,
        )
        this.fetched = true
        this.repaymentDataSummary = data
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async repaymentBalance(sellerId: string) {
      try {
        this.loading = true
        const { $lenkieBankingApi } = useNuxtApp()

        if (!sellerId) return

        const { data = {} } = await $lenkieBankingApi.get(
          `/balances/${sellerId}`,
        )
        this.fetched = true
        this.balances = data
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
  },
})
