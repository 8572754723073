<template>
  <div
    v-if="!balance"
    class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4"
  >
    <LoadersShimmerLoader
      v-for="i in 4"
      :key="i"
      class="h-[5.625rem] rounded-lg"
    />
  </div>
  <div v-else class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
    <div>
      <div
        class="relative flex cursor-pointer items-center gap-x-5 rounded-lg border border-[#E2E8F0] bg-white px-4 py-4 hover:bg-gray-50 hover:delay-75 md:py-6"
      >
        <div class="rounded-full bg-[#F3F7FF] p-2">
          <the-icon icon-name="tag" size="s" class-name="fill-none" />
        </div>
        <div>
          <p class="text-xs font-medium text-[#64748B]">TOTAL USED</p>
          <p class="text-base font-medium text-primary">
            {{
              new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2,
              }).format(balance.credit.used_amount)
            }}
          </p>
        </div>
        <TooltipProvider :delay-duration="0">
          <Tooltip>
            <TooltipTrigger as-child>
              <PhInfo
                :size="24"
                class="absolute bottom-3 right-4 text-[#758EC5]"
              />
            </TooltipTrigger>
            <TooltipContent>
              <p>
                This is the cumulative amount utilized from your credit
                facility.
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>

    <div>
      <div
        class="relative flex cursor-pointer items-center gap-x-5 rounded-lg border border-[#E2E8F0] bg-white px-4 py-6 hover:bg-gray-50 hover:delay-75"
      >
        <div class="rounded-full bg-[#F3F7FF] p-2">
          <the-icon
            icon-name="arrow-arc-left"
            size="s"
            class-name="fill-none"
          />
        </div>
        <div>
          <p class="text-xs font-medium text-[#64748B]">TOTAL REPAYABLE</p>
          <p class="text-base font-medium text-primary">
            {{
              new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2,
              }).format(balance.credit.amount_repayable || 0)
            }}
          </p>
          <TooltipProvider :delay-duration="0">
            <Tooltip>
              <TooltipTrigger as-child>
                <PhInfo
                  :size="24"
                  class="absolute bottom-3 right-4 text-[#758EC5]"
                />
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  This includes the total amount used from your credit facility
                  along with the fees.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
    <div>
      <div
        class="relative flex cursor-pointer items-center gap-x-5 rounded-lg border border-[#E2E8F0] bg-white px-4 py-6 hover:bg-gray-50 hover:delay-75"
      >
        <div class="rounded-full bg-[#F3F7FF] p-2">
          <the-icon icon-name="receipt" size="s" class-name="fill-none" />
        </div>
        <div>
          <p class="text-xs font-medium text-[#64748B]">TOTAL REPAID</p>
          <p class="text-base font-medium text-primary">
            {{
              new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2,
              }).format(balance.credit.net_repaid || 0)
            }}
          </p>
          <TooltipProvider :delay-duration="0">
            <Tooltip>
              <TooltipTrigger as-child>
                <PhInfo
                  :size="24"
                  class="absolute bottom-3 right-4 text-[#758EC5]"
                />
              </TooltipTrigger>
              <TooltipContent>
                <p>This represents the total sum you've already paid back.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
    <div>
      <div
        class="relative flex cursor-pointer items-center gap-x-5 rounded-lg border border-[#E2E8F0] bg-white px-4 py-6 hover:bg-gray-50 hover:delay-75"
      >
        <div class="rounded-full bg-[#F3F7FF] p-2">
          <the-icon icon-name="receipt" size="s" class-name="fill-none" />
        </div>
        <div>
          <p class="text-xs font-medium text-[#64748B]">OUTSTANDING</p>
          <p class="text-base font-medium text-primary">
            {{
              new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2,
              }).format(balance.credit.net_outstanding || 0)
            }}
          </p>
          <TooltipProvider :delay-duration="0">
            <Tooltip>
              <TooltipTrigger as-child>
                <PhInfo
                  :size="24"
                  class="absolute bottom-3 right-4 text-[#758EC5]"
                />
              </TooltipTrigger>
              <TooltipContent align="end">
                <p>This denotes the current balance you owe.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PhInfo } from '@phosphor-icons/vue'
import TheIcon from '@/components/shared/the-icon.vue'
import { useRepayments } from '@/stores/repayments'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

const repayment = useRepayments()
const balance = computed(() => repayment?.balances || null)
// const summary = computed(() => repayment?.repaymentDataSummary || {})
</script>
