type NumberRef = Ref<number>
export function useLocalPagination<T>(
  items: Ref<T[]> | ComputedRef<T[]>,
  currentPage: NumberRef,
  itemsPerPage: NumberRef,
) {
  const paginatedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage.value
    const endIndex = startIndex + itemsPerPage.value
    return items.value.slice(startIndex, endIndex)
  })

  const paginationInfo = computed(() => {
    const totalPages = Math.ceil(items.value.length / itemsPerPage.value)
    return {
      total: items.value.length,
      totalPages,
      hasNextPage: currentPage.value < totalPages,
      hasPrevPage: currentPage.value > 1,
      isLastPage: currentPage.value === totalPages,
    }
  })

  function goToPage(where: 'back' | 'forward' | number) {
    const { hasPrevPage, hasNextPage, totalPages } = paginationInfo.value

    if (where === 'back' && hasPrevPage) {
      currentPage.value--
    } else if (where === 'forward' && hasNextPage) {
      currentPage.value++
    } else if (typeof where === 'number' && where >= 1 && where <= totalPages) {
      currentPage.value = where
    }
  }

  return {
    data: paginatedItems,
    paginationInfo,
    goToPage,
  }
}
