<template>
  <template v-if="historicalRepayments.length !== 0">
    <div class="grid grid-cols-2 border-b border-t bg-white px-4 py-5">
      <div class="text-sm font-medium text-primary">Date paid</div>
      <div class="text-left text-sm font-medium text-primary">
        Total repayment amount
      </div>
      <div></div>
    </div>
    <div>
      <div class="custom-scrollbar max-h-[600px] overflow-auto">
        <div v-for="(item, idx) in repayments" :key="idx" class="">
          <div
            class="relative flex items-center justify-between border-b bg-white px-4 last:border-none hover:bg-slate-50"
          >
            <div
              class="grid w-full cursor-pointer grid-cols-2 py-5"
              @click.prevent="toggleViewVendor(idx)"
            >
              <div class="inline-flex items-center gap-x-3">
                <div>
                  <svg
                    :class="
                      activeViewedVendors.includes(idx) ? '-rotate-90' : ''
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9 18L15 12L9 6"
                      stroke="#475569"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="text-sm font-medium text-primary">
                  {{
                    item.due_date
                      ? dayjs(item.due_date).format('DD-MM-YYYY')
                      : ''
                  }}
                </div>
                <div
                  class="hidden rounded-full bg-[#E7EEFF] px-1.5 py-0.5 text-xs font-normal text-[#475467] xl:flex"
                >
                  {{ item.repayments.length + ' ' + 'bill(s) found' }}
                </div>
              </div>
              <div class="text-sm font-medium text-primary">
                {{
                  new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                    minimumFractionDigits: 2,
                  }).format(item.amount || 0)
                }}
              </div>
              <div class=""></div>
            </div>
            
            <div class="absolute right-3 top-0 flex h-full items-center">
              <Button
                variant="ghost"
                class="text-primary"
                :disabled="isExporting"
                @click="generateReceipt(item)"
              >
                <ph-spinner
                  v-if="isExporting"
                  size="24"
                  class="mr-2 animate-spin"
                />
                <the-icon
                  icon-name="receipt-g"
                  size="xs"
                  class-name="fill-none md:mr-2"
                />
                <span class="hidden xl:flex">Generate receipt</span>
              </Button>
            </div>
          </div>
          <div :class="!activeViewedVendors.includes(idx) ? 'hidden' : ''">
            <div class="hidden xl:block">
              <div class="px-4 py-2">
                <div class="grid grid-cols-6">
                  <div class="w-[254px] text-sm font-medium text-primary">
                    Original Invoice Date
                  </div>
                  <div class="w-[254px] text-sm font-medium text-primary">
                    Vendor Paid
                  </div>
                  <div class="w-[254px] text-sm font-medium text-primary">
                    Principal Amount
                  </div>
                  <div class="w-[254px] text-sm font-medium text-primary">
                    Fee Amount
                  </div>
                  <div class="w-[254px] text-sm font-medium text-primary">
                    Total Repayment Amount
                  </div>
                  <div class="text-sm font-medium text-primary"></div>
                </div>
              </div>
              <div class="cursor-pointer bg-[#F1F5F9] px-4 py-2">
                <div
                  v-for="(x, index) in item.repayments"
                  :key="index"
                  class="grid grid-cols-6 py-2"
                >
                  <div class="cursor-pointer text-sm font-medium text-primary">
                    {{ dayjs(x.payment_date).format('DD-MM-YYYY') }}
                  </div>
                  <div
                    class="max-w-[140px] cursor-pointer truncate text-sm font-medium text-primary"
                    style="text-transform: capitalize"
                  >
                    {{ x.vendor_name }}
                  </div>
                  <div class="cursor-pointer text-sm font-medium text-primary">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.principal_amount || 0)
                    }}
                  </div>
                  <div class="cursor-pointer text-sm font-medium text-primary">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.fee_amount || 0)
                    }}
                  </div>
                  <div class="cursor-pointer text-sm font-medium text-primary">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.total_amount || 0)
                    }}
                  </div>
                  <button
                    class="flex w-max items-center gap-1 text-sm text-primary disabled:cursor-wait disabled:opacity-60"
                    :disabled="isDownloadingInvoice(x.invoice_url)"
                    @click.prevent="downloadInvoice(x.invoice_url)"
                  >
                    <ph-spinner
                      v-if="isDownloadingInvoice(x.invoice_url)"
                      class="animate-spin text-current"
                      :size="20"
                    />
                    <ph-paperclip v-else :size="20" />

                    Download invoice
                  </button>
                </div>
              </div>
            </div>
            <div class="xl:hidden">
              <div
                v-for="(x, index) in item.repayments"
                :key="index"
                class="relative mb-2 flex items-start justify-between bg-[#F1F5F9] px-4 py-10"
              >
                <div
                  class="flex flex-col gap-3 text-sm font-medium text-primary"
                >
                  <div class="">Original Invoice Date</div>
                  <div class="">Vendor Paid</div>
                  <div class="">Principal Amount</div>
                  <div class="">Fee Amount</div>
                  <div class="">Total Repayment Amount</div>
                  <div class=""></div>
                </div>
                <div
                  class="flex flex-col gap-3 text-sm font-normal text-primary"
                >
                  <div class="">
                    {{ dayjs(x.invoice_date).format('DD-MM-YYYY') }}
                  </div>
                  <div class="">
                    {{ x.vendor_name }}
                  </div>
                  <div class="">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.principal_amount || 0)
                    }}
                  </div>
                  <div class="">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.fee_amount || 0)
                    }}
                  </div>
                  <div class="">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.total_amount || 0)
                    }}
                  </div>
                </div>
                <div
                  class="absolute inset-x-0 bottom-1 flex items-center justify-center pb-2"
                >
                  <span
                    class=""
                    @click.prevent="downloadInvoice(x.invoice_url)"
                  >
                    <a
                      href="#"
                      class="inline-flex gap-x-1 text-sm text-primary underline"
                    >
                      <the-icon
                        icon-name="clip"
                        size="xs"
                        class="fill-none"
                      />Download invoice</a
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between p-3">
        <div class="hidden items-center gap-x-3 lg:flex">
          <div>
            <Select v-model.number="itemsPerPage">
              <SelectTrigger class="h-10 w-[70px]">
                <SelectValue :placeholder="String(itemsPerPage)" />
              </SelectTrigger>
              <SelectContent side="top" class="min-w-[100px]">
                <SelectItem
                  v-for="pageSize in ['10', '20', '30', '40', '50']"
                  :key="pageSize"
                  :value="pageSize"
                >
                  {{ pageSize }}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <span class="text-sm font-medium text-slate-500">rows per page</span>
        </div>
        <div v-if="paginationInfo && paginationInfo.totalPages > 1">
          <Pagination
            :total="paginationInfo.total"
            :sibling-count="1"
            :items-per-page="itemsPerPage"
            show-edges
            :default-page="page"
          >
            <PaginationList v-slot="{ items }" class="flex items-center gap-1">
              <PaginationFirst @click="() => goToPage('page', 1)" />
              <PaginationPrev
                @click="() => paginationInfo!.hasPrevPage && goToPage('back')"
              />

              <template v-for="(item, index) in items">
                <PaginationListItem
                  v-if="item.type === 'page'"
                  :key="index"
                  :value="item.value"
                  as-child
                >
                  <Button
                    class="h-10 w-10 p-0"
                    :variant="item.value === page ? 'default' : 'outline'"
                    @click="() => goToPage(item.value)"
                  >
                    {{ item.value }}
                  </Button>
                </PaginationListItem>
                <PaginationEllipsis v-else :key="item.type" :index="index" />
              </template>

              <PaginationNext
                @click="
                  () => paginationInfo!.hasNextPage && goToPage('forward')
                "
              />
              <PaginationLast
                @click="() => goToPage(paginationInfo.totalPages)"
              />
            </PaginationList>
          </Pagination>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div
      class="mx-auto mt-3 flex items-center justify-center rounded-lg bg-white pb-56 pt-20 text-center"
    >
      <div>
        <div class="mx-auto h-[120px] w-[140px] lg:h-[123px] lg:w-[165px]">
          <the-icon
            icon-name="no-repayment"
            size="auto"
            class-name="fill-none"
          />
        </div>
        <h3 class="mt-3 text-base font-bold text-primary lg:mt-8 lg:text-xl">
          No repayments for now
        </h3>
        <p class="mt-1 max-w-sm text-sm font-normal text-gray-600">
          You can track your repayment history here.
        </p>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import JSPDF from 'jspdf'
import { useMutation } from '@tanstack/vue-query'
import { PhSpinner, PhPaperclip } from '@phosphor-icons/vue'
import TheIcon from '@/components/shared/the-icon.vue'
import { useProfileStore } from '@/stores/profile'
import 'jspdf-autotable'
import { useToast } from '~/components/ui/toast/use-toast'
import type { GetHistoricalRepaymentsResponse } from '~/types/apiResponse/repayments.response'
import { useAuthStore } from '~/stores/auth'

interface Props {
  historicalRepayments: GetHistoricalRepaymentsResponse
}

const props = defineProps<Props>()

const page = ref(1)
const itemsPerPage = ref('20')
const itemsPerPageNUMBER = computed(() => +itemsPerPage.value)

const {
  data: repayments,
  goToPage,
  paginationInfo,
} = useLocalPagination(
  computed(() => props.historicalRepayments),
  page,
  itemsPerPageNUMBER,
)

watch(itemsPerPage, () => {
  page.value = 1
})

const profileStore = useProfileStore()
const authStore = useAuthStore()

const companyName = computed(
  () => profileStore?.data?.organisation.trading_name,
)

const companyEmail = computed(() => profileStore?.profile?.work_email_address)

const { organisationId } = storeToRefs(profileStore)
const { personId } = storeToRefs(authStore)

const activeViewedVendors = ref<number[]>([])

const { $lenkieBankingApi, $api } = useNuxtApp()

const toggleViewVendor = (i: number) => {
  const index = activeViewedVendors.value.indexOf(i)
  if (index < 0) return activeViewedVendors.value.push(i)
  activeViewedVendors.value.splice(index, 1)
}

const { toast } = useToast()

const currentlyDownloadingURLs = ref<string[]>([])

function addOrRemoveToCurrentDownloadURLs(url: string) {
  const index = currentlyDownloadingURLs.value.indexOf(url)
  if (index === -1) {
    currentlyDownloadingURLs.value.push(url)
  } else {
    currentlyDownloadingURLs.value.splice(index, 1)
  }
}

function isDownloadingInvoice(url: string) {
  return currentlyDownloadingURLs.value.includes(url)
}

const downloadInvoice = async (urls: string) => {
  try {
    addOrRemoveToCurrentDownloadURLs(urls)
    let fileCount = 0
    for (const url of urls.split(',')) {
      const response = await $lenkieBankingApi.get(
        `/FileUpload/downloadFile?filePath=${url.trim()}`,
        {
          responseType: 'blob',
        },
      )

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      })

      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = url.trim()
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      fileCount++
    }
    const toastMessage = `${fileCount} ${fileCount < 2 ? 'file' : 'files'} downloaded successfully`
    toast({
      title: 'Invoice download',
      description: toastMessage,
    })
  } catch (error) {
    toast({
      title: 'Failed to download invoice',
      description: 'An error occured, please contact support',
      variant: 'destructive',
    })
  } finally {
    addOrRemoveToCurrentDownloadURLs(urls)
  }
}

const _generateReceipt = (items: GetHistoricalRepaymentsResponse[number]) => {
  try {
    const doc = new JSPDF()
    const history = items.repayments.map((item) => {
      return {
        original_bill_date: dayjs(item.invoice_date).format('DD-MM-YYYY'),
        trading_name: item.vendor_name,
        amount: item.principal_amount,
        fee_percentage: item.fee_amount,
        total_repayment: item.total_amount,
      }
    })

    const principalAmount = history.reduce((accumulator, item) => {
      const amount = item.amount
      return accumulator + amount
    }, 0)

    const totalPaid = history.reduce((accumulator, item) => {
      const amount = item.total_repayment
      return accumulator + amount
    }, 0)

    const newDate = new Date()
    const dayjsDate = dayjs(newDate).format('DD-MM-YYYY')

    doc.addImage(
      'https://lenkie-assets.s3.eu-west-1.amazonaws.com/lenkie-logo.png',
      'PNG',
      15,
      10,
      30,
      10,
    )

    doc.setTextColor('#0A2540')
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(14)
    doc.text(`${companyName.value}`, 15, 35)

    doc.setFont('helvetica', 'normal')
    doc.setTextColor('#0A2540')
    doc.setFontSize(12)
    doc.text(`${companyEmail.value}`, 15, 42)
    doc.setTextColor('#0A2540')
    doc.setFontSize(12)
    doc.text(`${dayjsDate}`, 15, 50)

    // Set text color
    doc.setTextColor('#0A2540')
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(14)
    doc.text(
      `Repayment summary for ${dayjs(items.due_date).format('DD-MM-YYYY')}`,
      15,
      65,
    )

    doc.setDrawColor('#DFE4EA')
    doc.setFillColor('#FAFCFF')
    doc.roundedRect(15, 70, 180, 15, 1, 1, 'FD')

    // Add text
    doc.setTextColor('#0A2540')
    doc.setFontSize(10)
    doc.setFont('helvetica', 'normal')
    doc.text('Total principal amount:', 17, 75)
    doc.text(
      `${
        principalAmount.toLocaleString('en-US', {
          style: 'currency',
          currency: 'GBP',
        }) ?? 0
      }`,
      170,
      75,
    )

    doc.setTextColor('#0A2540')
    doc.setFontSize(10)
    doc.setFont('helvetica', 'bold')
    doc.text('Total amount repaid:', 17, 81)
    doc.text(
      `${
        totalPaid.toLocaleString('en-US', {
          style: 'currency',
          currency: 'GBP',
        }) ?? 0
      }`,
      170,
      81,
    )

    doc.setFontSize(14)
    const columns = [
      { header: 'Original Invoice Date', dataKey: 'original_bill_date' },
      { header: 'Vendor paid', dataKey: 'trading_name' },
      { header: 'Principal amount', dataKey: 'amount' },
      { header: 'Fee', dataKey: 'fee_percentage' },
      { header: 'Total repayment', dataKey: 'total_repayment' },
    ]
    const data = history.map((dx) => {
      return {
        original_bill_date: dx.original_bill_date ?? '-',
        trading_name: dx.trading_name ?? '-',
        amount:
          dx?.amount?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'GBP',
          }) ?? 0,
        fee_percentage:
          dx?.fee_percentage?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'GBP',
          }) ?? 0,
        total_repayment:
          dx.total_repayment.toLocaleString('en-US', {
            style: 'currency',
            currency: 'GBP',
          }) ?? 0,
      }
    })

    doc.autoTable({
      head: [columns.map((col) => col.header)],
      body: data,
      columns,
      startY: 90,
      styles: {
        font: 'helvetica',
        fontSize: 10,
      },
      headStyles: {
        fillColor: '#1A2D5B',
        textColor: '#FFFFFF',
        fontStyle: 'bold',
        cellPadding: 3,
        minCellWidth: 10,
      },
      bodyStyles: {
        fillColor: '#FAFCFF',
        lineWidth: 0.05,
      },
    })

    doc.save('lenkie_statement.pdf')
  } catch (error) {}
}

const { mutate: mutateFn, isPending: isExporting } = useMutation({
  mutationFn: $api.banking.repayments.exportRepayments,
})

const generateReceipt = (item: GetHistoricalRepaymentsResponse[number]) => {
  if (!organisationId.value || !personId.value) return
  mutateFn(
    {
      export_type: 'Historic',
      organisation_id: organisationId.value,
      person_id: personId.value,
      from_date: item.due_date.toString(),
      to_date: item.due_date.toString(),
    },
    {
      onSuccess() {
        toast({
          title: 'Repayment Export',
          description: 'Repayment exported successfully!',
        })
      },

      onError() {
        toast({
          title: 'Error',
          description: 'Failed',
          variant: 'destructive',
        })
      },
    },
  )
}
</script>
