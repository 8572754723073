<template>
  <div class="px-4 pt-4 md:px-8 md:pt-10">
    <div class="w-[85%] rounded-lg pb-3">
      <button
        class="flex cursor-pointer items-center font-bold text-[#374151]"
        @click.prevent="backToHome"
      >
        <the-icon
          icon-name="chevron-right"
          size="s"
          class="rotate-180 fill-none"
        />

        Back
      </button>
    </div>
    <div class="rounded-xl border border-[#E2E8F0] bg-white p-4">
      <h1 class="pb-4 text-xl font-bold text-primary">Repayment summary</h1>
      <repayment-cards />
    </div>

    <template v-if="isLoading || totalRepayments === null">
      <div class="mt-4">
        <div class="overflow-hidden rounded-xl border bg-white">
          <div class="flex items-center justify-between gap-2 px-4 py-3">
            <LoadersShimmerLoader class="h-10 w-full rounded-md" />
          </div>
          <LoadersTableLoader2
            :columns="2"
            :rows="8"
            :custom-column-width="[{ columnIndex: 1, width: '60%' }]"
          />
        </div>
      </div>
    </template>
    <template v-else-if="totalRepayments > 1">
      <Tabs v-model="activeTab" class="mt-4 bg-white">
        <div
          class="items-center justify-between px-4 pb-2 pt-4 md:flex md:pt-5"
        >
          <TabsList class="mb-3 grid w-full grid-cols-2 md:w-[353px]">
            <TabsTrigger :value="tabs[0]" class="text-sm text-primary">
              Upcoming repayments
            </TabsTrigger>
            <TabsTrigger :value="tabs[1]" class="text-sm text-primary">
              Repayments history
            </TabsTrigger>
          </TabsList>
          <div>
            <Button
              :disabled="isPending"
              variant="outline"
              class="w-full gap-x-2 rounded-[6px] text-primary md:w-auto"
              @click="exportToCSV"
            >
              <send-icon class="h-4 w-4 stroke-current stroke-2"></send-icon>
              Export
              <the-icon
                v-show="isPending"
                icon-name="spinner"
                size="s"
                class="animate-spin text-gray-400"
              />
            </Button>
            <!-- <Button variant="ghost" class="gap-x-2 text-primary"> Filters</Button> -->
          </div>
        </div>
        <TabsContent :value="tabs[0]" class="w-full">
          <upcoming-repayments
            v-if="upcomingRepaymentsData"
            :upcoming-repayments="upcomingRepaymentsData"
          />
        </TabsContent>
        <TabsContent :value="tabs[1]">
          <historical-repayments
            v-if="historicalRepaymentsData"
            :historical-repayments="historicalRepaymentsData"
          />
        </TabsContent>
      </Tabs>
    </template>
    <template v-if="typeof totalRepayments === 'number' && totalRepayments < 1">
      <div
        class="mx-auto mt-3 flex items-center justify-center rounded-xl bg-white pb-56 pt-[134px] text-center"
      >
        <div>
          <div class="mx-auto h-[120px] w-[140px] lg:h-[123px] lg:w-[165px]">
            <the-icon
              icon-name="no-repayment"
              size="auto"
              class-name="fill-none"
            />
          </div>
          <h3 class="mt-3 text-base font-bold text-primary lg:mt-8 lg:text-xl">
            No upcoming repayments for now
          </h3>
          <p class="mx-auto mt-2 max-w-md text-sm text-slate-500">
            You can track your upcoming repayments and repayment history once
            you start making use of your credit facility
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useMutation, useQuery } from '@tanstack/vue-query'
import { SendIcon } from 'lucide-vue-next'
import { watchImmediate } from '@vueuse/core'
import TheIcon from '@/components/shared/the-icon.vue'
import RepaymentCards from '@/components/modules/repayments/repayment-cards.vue'
import UpcomingRepayments from '@/components/modules/repayments/upcoming-repayments.vue'
import HistoricalRepayments from '@/components/modules/repayments/historical-repayments.vue'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useProfileStore } from '@/stores/profile'
import { useRepayments } from '@/stores/repayments'
import { useToast } from '@/components/ui/toast'

const { toast } = useToast()

const profile = useProfileStore()
const profileStore = useProfileStore()
const { organisationId } = storeToRefs(profileStore)
const repayment = useRepayments()
const router = useRouter()
const { $api, $event } = useNuxtApp()

const tabs = ['upcoming-repayments', 'historical-repayments'] as const
const activeTab = ref<(typeof tabs)[number]>(tabs[0])

useHead({
  title() {
    if (activeTab.value === 'historical-repayments') {
      return 'Lenkie | Repayments - Historical'
    } else {
      return 'Lenkie | Repayments - Upcoming'
    }
  },
})

useSyncTabAndUrl(tabs, activeTab)

const sellerId = computed(() => profile.data?.organisation?.id)

const backToHome = () => {
  router.push('/dashboard')
}

const { mutate, isPending } = useMutation({
  mutationFn: $api.banking.repayments.exportCreditFacilityAsCSV,
  onSuccess() {
    $event('track:mixpanel', {
      event: 'Export repayments',
      data: {},
    })
    toast({
      title: 'Repayments exported',
      description:
        'Your repayments have been exported and has been sent as a document to your email.',
    })
  },
  onError(err) {
    toast({
      title: 'Error',
      description: err.message,
      variant: 'destructive',
    })
  },
})

function exportToCSV() {
  mutate({ organisationId: sellerId.value })
}

watchImmediate(organisationId, (newVal) => {
  if (newVal) {
    repayment.repaymentBalance(newVal)
    repayment.repaymentSummary(newVal)

    $event('track:mixpanel', {
      event: 'Repayment Page Viewed',
      data: {
        viewed: 'repayment',
      },
    })
  }
})

onBeforeRouteLeave(() => {
  $event('track:mixpanel', {
    event: 'Repayment Page: Leave',
    data: {},
  })
})

const { isLoading: isUpcomingRepaymentsLoading, data: upcomingRepaymentsData } =
  useQuery({
    queryKey: [
      'get-upcoming-repayments',
      { organisationId: organisationId.value },
    ],
    queryFn: () =>
      $api.banking.repayments.getUpcomingRepayments({
        organisationId: organisationId.value!,
      }),
    select: (data) => data.data,
    enabled: computed(() => !!organisationId.value),
  })

const {
  isLoading: isHistoricalRepaymentsLoading,
  data: historicalRepaymentsData,
} = useQuery({
  queryKey: ['get-historical-repayments', organisationId.value],
  queryFn: () =>
    $api.banking.repayments.getHistoricalRepayments({
      organisationId: organisationId.value!,
    }),
  select: (data) => data.data,
  enabled: computed(() => !!organisationId.value),
})

const isLoading = computed(
  () =>
    isUpcomingRepaymentsLoading.value || isHistoricalRepaymentsLoading.value,
)

const totalRepayments = computed(() => {
  if (!upcomingRepaymentsData.value || !historicalRepaymentsData.value)
    return null

  return (
    upcomingRepaymentsData.value.length + historicalRepaymentsData.value.length
  )
})
</script>
